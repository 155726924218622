* {
  box-sizing: border-box;
}

.Portfolio {
  font-family: monaco, monospace;
  --main-bg-color: #f6e7cb;
  --section-bg-color: #a6c48a;
  background-color: var(--main-bg-color);
}

.portfolio-header {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.grid-wrapper {
  display: block;
  unicode-bidi: isolate;
  position: relative;
  height: auto;
  max-width: 100%;
  padding-inline: 2em;
  padding-bottom: 2em;
}

.grid-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem;
}

/* Create four equal columns that sits next to each other */
.grid-column {
  flex: 25%;
  max-width: 25%;
  padding: 0 0.5rem;
}

.grid-column img {
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .grid-column {
    flex: 50%;
    max-width: 50%;
  }

  .grid-column .grid-item .project-title p {
    font-size: 1rem;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .grid-column {
    flex: 100%;
    max-width: 100%;
  }

  .grid-column .grid-item .project-title p {
    font-size: 1rem;
  }
}

.grid-item {
  margin-top: 1rem;
  transition: 0.5s ease;
  background-color: black;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;
  border-radius: 0.4em;
}

.project-image {
  max-width: 100%;
  border-radius: 0.4em;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.grid-item:hover .project-image {
  opacity: 0.4;
}

.grid-item:hover .project-title {
  opacity: 1;
}

.project-title {
  position: absolute;
  color: white;
  width: 100%;
  bottom: 0;
  font-size: 1rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.standard-section {
  margin-top: 7rem;
  padding-block: 7rem;
  text-align: left;
  display: flex;
  justify-content: center;
}

.standard-wrapper {
  background: var(--section-bg-color);
  padding: 3.125rem;
  margin-inline: 5.275rem;
  border-radius: 0.5em;
}

.about-me-header-wrapper {
  margin-bottom: 1rem;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-inline: -0.938rem;
}

.about-me-header {
  margin-top: -9.375rem;
  text-align: center;
  margin-inline: auto;
}

.about-me-image-wrapper {
  width: 15rem;
  height: 15rem;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}

.about-me-image-wrapper img {
  position: absolute;
  top: 69%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-me-text {
  margin-inline: 10rem;
}

.contact-section {
  margin-top: 7rem;
  padding-block: 0 7rem;
  text-align: left;
  display: flex;
  justify-content: center;
}

.contact-wrapper {
  background-color: var(--section-bg-color);
  padding: 3.125rem;
  display: flex;
  flex-direction: column;
}

.contact-header {
  margin-bottom: 1rem;
  justify-content: center;
  text-align: center;
}

.contact-info {
  display: flex;
  flex-direction: row;
}

.contact-form {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.contact-input {
  margin-bottom: 1rem;
}

.contact-input input, .contact-input textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-input button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.contact-input button:hover {
  background-color: #0056b3;
}

.vertical-line {
  width: 1px;
  background-color: var(--main-bg-color);
  margin: 0 2rem;
}

.social-media-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  justify-content: space-evenly;
  height: 100%;
}


.social-media-list a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
}

.social-media-list i {
  margin-right: 10px;
}